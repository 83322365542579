import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { InputRule, ProductDefinitionInputParameter, SerializedBlocklyWorkspaceState } from 'mid-addin-lib';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BLOCKLY_EVENTS_TO_UPDATE, DATA_STORE_DEBOUNCE_TIME, muiDarkPaletteMode } from '../constants';
import { handleWorkspaceSwitch } from '../utils';
import { addNewInputsToWorkspace, disableOrphanedBlocks, initializeFormBlocks } from './FormCodeblocks.utils';
import { debounce } from 'lodash';
import { formRulesKey } from './FormCodeblocks.constants';
import { initializeFormCodeblocksExtensions, initializeFormTabControlsExtension } from './FormCodeblocks.extensions';
import { formCodeblocksToolbox } from './FormCodeblocks.toolbox';
import { Abstract } from 'blockly/core/events/events_abstract';
import { darkTheme, lightTheme } from '../blocklyConfig';
import { useTheme } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface useFormCodeblocksProps {
  hidden: boolean;
  inputs: ProductDefinitionInputParameter[];
  formCodeblocksWorkspace?: SerializedBlocklyWorkspaceState;
  setFormCodeBlocksWorkspace: (workspace: SerializedBlocklyWorkspaceState) => void;
  setFormCodeBlocksRules: (rule: InputRule) => void;
  recentlyAdoptedInputs: ProductDefinitionInputParameter[];
  setRecentlyAdoptedInputs: (inputs: ProductDefinitionInputParameter[]) => void;
}
interface useFormCodeblocksReturn {
  formWorkspaceDiv: React.RefObject<HTMLDivElement>;
}

const useFormCodeblocks = ({
  hidden,
  inputs,
  formCodeblocksWorkspace,
  setFormCodeBlocksWorkspace,
  setFormCodeBlocksRules,
  recentlyAdoptedInputs,
  setRecentlyAdoptedInputs,
}: useFormCodeblocksProps): useFormCodeblocksReturn => {
  const theme = useTheme();
  const [initialBlocksLoaded, setInitialBlocksLoaded] = useState(false);
  const formWorkspaceDiv = useRef<HTMLDivElement>(null);
  const workspace = useRef<Blockly.WorkspaceSvg>();
  const isWorkspaceDoneLoading = useRef<boolean>(false);
  const { enableBlocklyFormTabs } = useFlags();

  // We only need the initial list of inputs to initialize the workspace.
  // We don't want to reinitialize the workspace when the inputs change.
  // Hence, we only reference the inputs
  const inputsRef = useRef<ProductDefinitionInputParameter[]>(inputs);

  const getFormCode = useCallback(() => {
    javascriptGenerator.STATEMENT_PREFIX = '';
    const codeWithComments = javascriptGenerator.workspaceToCode(workspace.current);

    //Strip comments from code
    return codeWithComments.replace(/\/\/.*/g, '');
  }, []);

  const saveRulesAndWorkspace = useCallback(
    (workspace: Blockly.WorkspaceSvg) => {
      if (workspace.isDragging()) {
        return;
      }

      const newWorkspace = Blockly.serialization.workspaces.save(workspace);
      setFormCodeBlocksWorkspace(newWorkspace);

      setFormCodeBlocksRules({
        key: formRulesKey,
        code: getFormCode(),
      });
    },
    [getFormCode, setFormCodeBlocksRules, setFormCodeBlocksWorkspace],
  );

  const saveRulesAndWorkspaceDebounced = useMemo(
    () => debounce(saveRulesAndWorkspace, DATA_STORE_DEBOUNCE_TIME),
    [saveRulesAndWorkspace],
  );

  // We need to keep a reference to the debounced function to cancel it when the component unmounts
  const saveWorkspaceRef = useRef(saveRulesAndWorkspaceDebounced);

  const handleWorkspaceChange = useCallback(
    (event: Abstract): void => {
      if (event.type === Blockly.Events.FINISHED_LOADING && workspace.current) {
        isWorkspaceDoneLoading.current = true;

        addNewInputsToWorkspace(recentlyAdoptedInputs, workspace.current);
        setRecentlyAdoptedInputs([]);

        // Set initial preview for rules once workspace is done loading
        saveRulesAndWorkspace(workspace.current);
      } else if (isWorkspaceDoneLoading.current && workspace.current && BLOCKLY_EVENTS_TO_UPDATE.includes(event.type)) {
        saveRulesAndWorkspaceDebounced(workspace.current);
      }
    },
    [recentlyAdoptedInputs, saveRulesAndWorkspace, saveRulesAndWorkspaceDebounced, setRecentlyAdoptedInputs],
  );

  useEffect(() => {
    const cancelDebounceBeforeUnmount = saveWorkspaceRef.current;
    if (formWorkspaceDiv.current) {
      workspace.current = Blockly.inject(formWorkspaceDiv.current, {
        toolbox: formCodeblocksToolbox,
      });
      initializeFormCodeblocksExtensions(inputsRef.current);
      initializeFormTabControlsExtension(enableBlocklyFormTabs);
      workspace.current.addChangeListener(disableOrphanedBlocks);
    }
    return () => {
      if (workspace.current) {
        cancelDebounceBeforeUnmount.cancel();
        workspace.current.dispose();
        setInitialBlocksLoaded(false);
      }
    };
  }, [enableBlocklyFormTabs]);

  useEffect(() => {
    handleWorkspaceSwitch(workspace.current, hidden);
    workspace.current?.scrollCenter();
  }, [hidden]);

  useEffect(() => {
    if (workspace.current && !initialBlocksLoaded) {
      if (formCodeblocksWorkspace) {
        Blockly.serialization.workspaces.load(formCodeblocksWorkspace, workspace.current);
      } else {
        workspace.current.clear();
        initializeFormBlocks(workspace.current, inputsRef.current);
      }

      workspace.current.scrollCenter();
      workspace.current.addChangeListener(handleWorkspaceChange);

      setInitialBlocksLoaded(true);
    }
  }, [formCodeblocksWorkspace, handleWorkspaceChange, initialBlocksLoaded, inputsRef]);

  // Theme
  useEffect(() => {
    if (theme.palette.mode === muiDarkPaletteMode) {
      workspace.current?.setTheme(darkTheme);
    } else {
      workspace.current?.setTheme(lightTheme);
    }
  }, [theme.palette.mode]);

  return { formWorkspaceDiv };
};

export default useFormCodeblocks;
