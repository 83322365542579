export const tabsTestIds = {
  tabSourceContent: 'tab-SOURCE_CONTENT',
  tabInputs: 'tab-INPUTS',
  tabRules: 'tab-RULES',
  tabOutputs: 'tab-OUTPUTS',
};

export const productDefinitionSelectionTestIds = {
  buttonEditProductDefinition: 'button-edit-product-definition',
  buttonDuplicateProductDefinition: 'button-duplicate-product-definition',
  buttonDeleteProductDefinition: 'button-delete-product-definition',
  buttonNewProductDefinition: 'button-new-product-definition',
};

export const previewThumbnailTestIds = {
  previewThumbnail: 'preview-thumbnail',
};

export const sourceContentTestIds = {
  topFolderSelection: 'top-folder-select',
  topAssemblyInput: 'top-assembly-input',
  ipjInput: 'ipj-input',
  ipjSelectButton: 'ipj-select-button',
  ipjReplaceButton: 'ipj-replace-button',
  configurableButton: 'configurable-button',
};

export const drawingThumbnailsTestIds = {
  redirectLinkToSourceContent: 'redirect-link-to-source-content',
  noDrawingThumbnailOutputRow: 'no-drawing-thumbnail-output-row',
  outputSection: 'drawing-thumbnail-output-section',
  previewImage: 'drawing-thumbnail-preview-image',
  outputsTable: 'drawing-thumbnail-outputs-table',
};

export const OutputsTestIds = {
  statusMaster: 'output-status-Master',
  statusBIM: 'output-status-BIM',
};

export const HeaderTestIds = {
  publishButton: 'publish-button',
  saveButton: 'save-product-definition-button',
  productDefinitionsLink: 'product-definitions-link',
};

export const publishTestIds = {
  publishButton: 'publish-product-button',
  buttonProductDefinitions: 'button-product-definitions',
  publishReleaseSummarySection: 'publish-release-summary-section',
  productDefinitionSummarySection: 'product-definition-summary-section',
  publishOutputsSummarySection: 'publish-outputs-summary-section',
  publishHeader: 'publish-header',
  publishWarningIcon: 'publish-warning-icon',
};

export const publishingInvalidtestIds = {
  incompleteReqsTitle: 'incomplete-requirements-title',
  sectionValid: 'section-valid',
  sectionInvalid: 'section-invalid',
  sectionValidIcon: 'section-valid-icon',
  sectionInvalidIcon: 'section-invalid-icon',
};
