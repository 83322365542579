import { InputRule, ProductDefinitionInputParameter, ProductDefinitionOutput } from 'mid-addin-lib';
import { OutputType } from '@adsk/offsite-dc-sdk';
import { TabProgress } from '../../types';
import { allRulesHaveInputsAdopted } from 'components/Rules/utils';

interface UpdateSourceContentProgressStateArgs {
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
}
export const updateSourceContentProgressState = ({
  topLevelFolder,
  inventorProject,
  assembly,
}: UpdateSourceContentProgressStateArgs): TabProgress => {
  let progressionState = TabProgress.PARTIALLY_COMPLETED;

  if (topLevelFolder && assembly && inventorProject) {
    progressionState = TabProgress.COMPLETED;
  }
  if (!topLevelFolder && !inventorProject && !assembly) {
    progressionState = TabProgress.EMPTY;
  }
  return progressionState;
};

export const updateParametersProgressState = (
  parameters: ProductDefinitionInputParameter[],
  isConfigurable?: boolean,
): TabProgress => {
  // If it is static content, then paramaters tab is not required to be completed
  if (isConfigurable === false) {
    return TabProgress.COMPLETED;
  }

  let progressionState = TabProgress.EMPTY;

  if (parameters.length) {
    progressionState = TabProgress.COMPLETED;
  } else {
    progressionState = TabProgress.EMPTY;
  }

  return progressionState;
};

export const updateRulesProgressState = (
  rules: InputRule[],
  parameters: ProductDefinitionInputParameter[],
  hasInputsError: boolean,
  recentlyAdoptedInputs: ProductDefinitionInputParameter[],
  isConfigurable?: boolean,
): TabProgress => {
  // If it is static content, then rules tab is not required to be completed
  if (isConfigurable === false) {
    return TabProgress.COMPLETED;
  }

  if (
    !hasInputsError &&
    recentlyAdoptedInputs.length === 0 &&
    (rules.length === 0 || allRulesHaveInputsAdopted(rules, parameters))
  ) {
    return TabProgress.COMPLETED;
  }
  return TabProgress.PARTIALLY_COMPLETED;
};

export const updateOutputsProgressState = (outputs: ProductDefinitionOutput[]): TabProgress => {
  let progressionState = TabProgress.EMPTY;

  const outputsMap = outputs.reduce((acc: { [key: string]: ProductDefinitionOutput }, output: ProductDefinitionOutput) => {
    acc[output.type] = output;
    return acc;
  }, {});

  // For now, we verify RFA output if there is one RFA completed output...
  const rfaOutput = outputsMap[OutputType.RFA];
  const isRFAOutputValid =
    rfaOutput && rfaOutput.options?.category && rfaOutput.options.family && rfaOutput.options.modelStates?.length;

  if (isRFAOutputValid) {
    progressionState = TabProgress.COMPLETED;
  } else if (rfaOutput) {
    progressionState = TabProgress.PARTIALLY_COMPLETED;
  }

  return progressionState;
};
