import Blockly, { Events, Block, BlockSvg } from 'blockly';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import {
  blocklyLabel,
  blocklyStatementInput,
  controlBlock,
  formContainerBlock,
  minusImageBase64,
  minusImageAltText,
  tabInputContainerIdPrefix,
  tabNameFieldPrefix,
  tabNumberFieldPrefix,
  tabStatementInputNamePrefix,
  formTabControlImageSize,
  namePrefixUniqueIdSeparator,
  MAX_TABS_ALLOWED_NUMBER,
} from './FormCodeblocks.constants';
import { isCurrentSelectedOptionValid } from '../utils';
import { Abstract } from 'blockly/core/events/events_abstract';
import { BlockMove } from 'blockly/core/events/events_block_move';
import { BlockCreate } from 'blockly/core/events/events_block_create';
import { blocklyInputsDropdown } from '../constants';
import { getDefaultInputLabel } from 'mid-utils';

export const initializeFormBlocks = (workspace: Blockly.WorkspaceSvg, inputs: ProductDefinitionInputParameter[]): void => {
  const formBlock = Blockly.serialization.blocks.append({ type: formContainerBlock }, workspace);
  const formBlockInput = formBlock.getInput(blocklyStatementInput);

  if (formBlock && formBlockInput !== null) {
    // create and connect all the inputs to the form block
    inputs
      .slice()
      .reverse()
      .forEach((input) => {
        const newBlock = Blockly.serialization.blocks.append({ type: controlBlock }, workspace);
        newBlock.getField(blocklyInputsDropdown)?.setValue(input.name);
        newBlock.getField(blocklyLabel)?.setValue(getDefaultInputLabel(input));
        newBlock.previousConnection?.connect(formBlockInput.connection!);
        formBlockInput.connection?.connect(newBlock.outputConnection!);
      });

    // Fire finished loading event
    Events.fire(new (Events.get(Events.FINISHED_LOADING))(workspace));
  }
};
export function labelValidator(block: BlockSvg, inputs: ProductDefinitionInputParameter[], newValue: string): string {
  const currentDropdownField = block.getField(blocklyInputsDropdown);
  const currentLabelField = block.getField(blocklyLabel);
  const currentInput = inputs.find((input) => input.name === currentDropdownField?.getValue());

  if (currentInput && currentLabelField) {
    const defaultCurrentLabelValue = getDefaultInputLabel(currentInput);
    // Check if label is the default value
    if (defaultCurrentLabelValue === currentLabelField.getValue() || currentLabelField.getValue() === '') {
      const newInput = inputs.find((input) => input.name === newValue);
      if (newInput) {
        const newDefaultCurrentValue = getDefaultInputLabel(newInput);
        currentLabelField.setValue(newDefaultCurrentValue);
      }
    }
  }
  return newValue;
}

export const disableOrphanedBlocks = (event: Abstract): void => {
  if (isBlockMoveEvent(event) || isBlockCreateEvent(event)) {
    if (!event.workspaceId || !event.blockId) {
      return;
    }
    const eventWorkspace = Blockly.common.getWorkspaceById(event.workspaceId);
    if (!eventWorkspace) {
      return;
    }

    let block = eventWorkspace.getBlockById(event.blockId);
    if (!block) {
      return;
    }
    const parent = block.getRootBlock();

    if (parent && parent.type === formContainerBlock) {
      const children = block.getDescendants(false);
      children.forEach((child) => {
        const inputsTextValue = child.getField(blocklyInputsDropdown)?.getText();
        if (inputsTextValue && !isCurrentSelectedOptionValid(inputsTextValue)) {
          child.setEnabled(false);
        } else {
          if (isBlockMarkedAsOrphaned(child)) {
            updateBlockOrphanedStatus(child, false);
            child.setEnabled(true);
          }
        }
      });
    } else if (block.outputConnection || block.previousConnection) {
      do {
        block.setEnabled(false);
        updateBlockOrphanedStatus(block, true);
        block = block.getNextBlock();
      } while (block);
    }
  }
};

export const updateBlockOrphanedStatus = (block: Block, orphanedStatus: boolean): void => {
  if (block.data) {
    const blockData = JSON.parse(block.data);
    block.data = JSON.stringify({ ...blockData, orphaned: orphanedStatus });
  } else {
    block.data = JSON.stringify({ orphaned: orphanedStatus });
  }
};
export const isBlockMarkedAsOrphaned = (block: Block): boolean => (block.data ? JSON.parse(block.data).orphaned : false);

const isBlockMoveEvent = (event: Abstract): event is BlockMove => event.type === Blockly.Events.MOVE;
const isBlockCreateEvent = (event: Abstract): event is BlockCreate => event.type === Blockly.Events.CREATE;

export const addNewInputsToWorkspace = (
  recentlyAdoptedInputs: ProductDefinitionInputParameter[],
  workspace: Blockly.WorkspaceSvg,
): void => {
  if (recentlyAdoptedInputs.length > 0) {
    // Get all the control blocks in the workspace
    const allControlBlocks = workspace.getAllBlocks(true)?.filter((block) => block.type === controlBlock);

    // Get all the inputs that are not already in the workspace
    // We do this, because there can be previously un-adopted inputs in the workspace that we don't want to add again
    const currentInputsInWorkspace = allControlBlocks?.map((block) => block.getField(blocklyInputsDropdown)?.getValue());
    const inputsToAddInWorkspace = recentlyAdoptedInputs.filter((input) => !currentInputsInWorkspace?.includes(input.name));

    // Get the form block
    const topBlocks = workspace.getTopBlocks(true);
    const formBlock = topBlocks?.find((block) => block.type === formContainerBlock);

    if (formBlock) {
      const formBlockInput = formBlock.getInput(blocklyStatementInput);
      if (formBlockInput) {
        // Get first block in the form block and iterate through all the blocks until the last block
        let currentBlock = formBlockInput.connection?.targetBlock();
        while (currentBlock?.nextConnection?.targetBlock()) {
          currentBlock = currentBlock.nextConnection?.targetBlock();
        }

        // create and connect all the inputs to the form block
        inputsToAddInWorkspace.forEach((input) => {
          if (!currentBlock || !currentBlock.nextConnection) {
            return;
          }

          const newBlock = Blockly.serialization.blocks.append({ type: controlBlock }, workspace);
          if (!newBlock.previousConnection) {
            return;
          }
          newBlock.getField(blocklyInputsDropdown)?.setValue(input.name);
          newBlock.getField(blocklyLabel)?.setValue(getDefaultInputLabel(input));
          newBlock.previousConnection?.connect(currentBlock.nextConnection);
          currentBlock.nextConnection?.connect(newBlock.previousConnection);
          currentBlock = newBlock;
        });
      }
    }
  }
};

function resetAllFormTabOrderNumbers(this: Blockly.Block) {
  const tabInputs = getTabInputContainers(this.inputList);
  tabInputs.forEach((tab, index) => {
    const newTabNumber = index + 1;
    const tabNumberField = tab.fieldRow.find((fieldRow) => fieldRow.name?.includes(tabNumberFieldPrefix));
    tabNumberField?.setValue(`Tab ${newTabNumber}`);
  });
}

interface TabInputComponents {
  tabInputContainerName: string;
  tabNumberFieldValue: string;
  tabNumberFieldName: string;
  tabNameFieldValue: string;
  tabNameFieldName: string;
  tabStatementInputName: string;
}

export function createTabInputComponents(
  this: Blockly.Block,
  {
    tabInputContainerName,
    tabNumberFieldValue,
    tabNumberFieldName,
    tabNameFieldValue,
    tabNameFieldName,
    tabStatementInputName,
  }: TabInputComponents,
): void {
  this.appendDummyInput(tabInputContainerName)
    .appendField(tabNumberFieldValue, tabNumberFieldName)
    .appendField(new Blockly.FieldTextInput(tabNameFieldValue), tabNameFieldName)
    .appendField(
      new Blockly.FieldImage(minusImageBase64, formTabControlImageSize, formTabControlImageSize, minusImageAltText, () => {
        Blockly.Events.setGroup(true);
        const oldExtraState = this.saveExtraState!();

        const _deleteTabBlock = deleteTabBlock.bind(this);
        const _resetAllFormTabOrderNumbers = resetAllFormTabOrderNumbers.bind(this);
        _deleteTabBlock(tabInputContainerName, tabStatementInputName);
        _resetAllFormTabOrderNumbers();

        const newExtraState = this.saveExtraState!();
        Events.fire(new (Events.get(Events.BLOCK_CHANGE))(this, 'mutation', null, oldExtraState, newExtraState));
        Blockly.Events.setGroup(false);
      }),
    );
  this.appendStatementInput(tabStatementInputName);
}

export function addTabBlock(this: Blockly.Block, tabNumber: number): void {
  const _createTabInputComponents = createTabInputComponents.bind(this);

  // Do not use the tabs index for the unique id, as multiple
  // tabs can occupy the same tabNumber, due to the addBlock & deleteBlock functionality
  const tabUniqueId = Math.round(Math.random() * Math.pow(MAX_TABS_ALLOWED_NUMBER, 3)).toString();
  const tabInputContainerName = getTabInputContainerName(tabUniqueId);
  const tabNumberFieldName = getTabNumberFieldName(tabUniqueId);
  const tabNumberFieldValue = `Tab ${tabNumber}`;
  const tabNameFieldName = getTabNameFieldName(tabUniqueId);
  const tabNameFieldValue = 'default';
  const tabStatementInputName = getStatementInputName(tabUniqueId);

  Blockly.Events.setGroup(true);
  const oldExtraState = this.saveExtraState!();
  _createTabInputComponents({
    tabInputContainerName,
    tabNumberFieldValue,
    tabNumberFieldName,
    tabNameFieldValue,
    tabNameFieldName,
    tabStatementInputName,
  });
  const newExtraState = this.saveExtraState!();
  Events.fire(new (Events.get(Events.BLOCK_CHANGE))(this, 'mutation', null, oldExtraState, newExtraState));
  Blockly.Events.setGroup(false);
}

function deleteTabBlock(this: Blockly.Block, tabInputContainerName: string, tabStatementInputName: string) {
  const tabInputs = getTabInputContainers(this.inputList);
  // If removing the last tab, add an empty statement block
  if (tabInputs.length === 1) {
    this.appendStatementInput(blocklyStatementInput);
  }
  this.removeInput(tabInputContainerName);
  this.removeInput(tabStatementInputName);
}

export function getTabInputContainers(inputList: Blockly.Input[]): Blockly.Input[] {
  return inputList.filter((input) => input.name.includes(tabInputContainerIdPrefix));
}

export const getUniqueIdFromTabInputContainerName = (inputName: string): string => {
  const inputNameParts = inputName.split(namePrefixUniqueIdSeparator);
  return inputNameParts[inputNameParts.length - 1];
};

const getTabInputContainerName = (tabUniqueId: string): string =>
  `${tabInputContainerIdPrefix}${namePrefixUniqueIdSeparator}${tabUniqueId}`;

export const getTabNameFieldName = (tabUniqueId: string): string =>
  `${tabNameFieldPrefix}${namePrefixUniqueIdSeparator}${tabUniqueId}`;

export const getTabNumberFieldName = (tabUniqueId: string): string =>
  `${tabNumberFieldPrefix}${namePrefixUniqueIdSeparator}${tabUniqueId}`;

export const getStatementInputName = (tabUniqueId: string): string =>
  `${tabStatementInputNamePrefix}${namePrefixUniqueIdSeparator}${tabUniqueId}`;
